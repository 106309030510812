import React from "react";

const SectionHead = ({ head, subHead, color }) => {
  return (
    <>
      <div className="section-heading" style={{ color: color ? color : "#978667" }}>{head}</div>
      {subHead && <p className="text-center para mt-2">{subHead}</p>}
    </>
  );
};

export default SectionHead;
