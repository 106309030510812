import html2pdf from 'html2pdf.js';
import React, { useContext, useState } from 'react';
import Logo from "../assets/about.png";
import AuthContext from '../context/AuthProvider';
const Success = () => {


    const [isPDFGenerationInProgress, setPDFGenerationInProgress] = useState(false);

    // Function to handle the download button click event
    const handleDownloadClick = () => {
        if (!isPDFGenerationInProgress) {
            setPDFGenerationInProgress(true);
            const element = document.getElementById('success-content');
            html2pdf()
                .from(element)
                .save('receipt.pdf')
                .then(() => setPDFGenerationInProgress(false))
                .catch(error => {
                    console.error('Error generating PDF:', error);
                    setPDFGenerationInProgress(false);
                });
        }
    };

    const {Payment,RoomCategoryCombination} = useContext(AuthContext)
    return (
        <div id='success-content' className='maxwidth px-5'>
            <div className='max-w-[550px] shadow-2xl flex flex-col items-center gap-5 mx-auto w-full p-5'>
                <div className='flex justify-center items-center h-[100px]'>
                    <img src={Logo} alt='' className='self-center w-[100px] h-[100%] object-cover' />
                </div>

                <div className='text-center px-5'>
                    <p className='text-[20px]  text-green-600 font-semibold'>
                        Payment Receipt<br />

                        <span className='text-[14px]'>For</span>
                        <br />
                        <span className='text-[18px]'>{Payment.HotelName}</span>
                    </p>
                </div>
                <div className=' px-5'>
                <strong>{Payment.PayStatus}</strong>
                </div>


                <div className='w-full font-semibold  text-[16px] md:text-[20px]  text-green-600'>
                    <p>Guest Infromation</p>
                </div>
                <div className='w-full flex flex-col gap-5 max-md:text-sm  text-green-600'>
                    <div className='flex justify-between gap-10 '>
                        <p>Name</p>
                        <p>{Payment.Name}</p>
                    </div>
                    <div className='flex justify-between  gap-10'>
                        <p>Phone</p>
                        <p>{Payment.Phone}</p>
                    </div>
                    <div className='flex justify-between gap-10'>
                        <p>Email</p>
                        <p className='break-words overflow-auto'>{Payment.Email}</p>
                    </div>
                </div>

                <div className='w-full font-semibold  text-[16px] md:text-[20px] text-green-600'>
                    <p>Booking status</p>
                </div>

                <div class="relative overflow-x-auto w-full">
                    <table class="w-full text-sm  text-left rtl:text-right">
                        <thead class="text-xs md:text-[12px]  text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Check In
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Check Out
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Adult
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Kid
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Checkin}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Checkout}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Adult}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Kid}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div className='w-full font-semibold  text-[16px] md:text-[20px] text-green-600'>
                    <p>Payment Infromation</p>
                </div>

                <div class="relative overflow-x-auto w-full">
                    <table class="w-full text-sm text-left rtl:text-right">
                        <thead class="text-xs md:text-[12px] text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Order ID
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Payment ID
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Order}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Payment}
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="relative overflow-x-auto w-full">
                    <table class="w-full text-sm text-left rtl:text-right">
                        <thead class="text-xs md:text-[12px] text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Payment Status
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Tax
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Amount Paid
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Total Amount
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.PayStatus}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Tax}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Paid}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Grandtotal}
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>




                <div className='w-full font-semibold  text-[16px] md:text-[20px] text-green-600'>
                    <p>Booked Room</p>
                </div>

                <div class="relative overflow-x-auto w-full">
                    <table class="w-full text-sm text-left rtl:text-right">
                        <tbody>
                        {Payment.Delux!=0?<tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {RoomCategoryCombination["DELUX"]}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Delux}
                                </td>

                            </tr>:""}
                        {Payment.SuperDelux!=0?<tr class="bg-white border-b ">
                            <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {RoomCategoryCombination["SUPERDELUX"]}
                            </td>
                            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {Payment.SuperDelux}
                            </td>

                        </tr>:""}
                        {Payment.Suite!=0?<tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {RoomCategoryCombination["SUITE"]}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.Suite}
                                </td>

                            </tr>:""}
                        {Payment.Premium!=0?<tr class="bg-white border-b ">
                            <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {RoomCategoryCombination["PREMIUM"]}
                            </td>
                            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {Payment.Premium}
                            </td>

                        </tr>:""}
                        {Payment.PremiereRetreat!=0?<tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {RoomCategoryCombination["PremiereRetreat"]}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.PremiereRetreat}
                                </td>

                            </tr>:""}
                        {Payment.EliteSuite!=0?<tr class="bg-white border-b ">
                            <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {RoomCategoryCombination["EliteSuite"]}
                            </td>
                            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {Payment.EliteSuite}
                            </td>

                        </tr>:""}
                        {Payment.GrandDeluxe!=0?<tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {RoomCategoryCombination["GrandDeluxe"]}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.GrandDeluxe}
                                </td>

                            </tr>:""}
                        {Payment.ImperialSuite!=0?<tr class="bg-white border-b ">
                            <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {RoomCategoryCombination["ImperialSuite"]}
                            </td>
                            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {Payment.ImperialSuite}
                            </td>

                        </tr>:""}
                        {Payment.SupremeRetreat!=0?<tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {RoomCategoryCombination["SupremeRetreat"]}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.SupremeRetreat}
                                </td>

                            </tr>:""}
                        {Payment.RoyalDeluxe!=0?<tr class="bg-white border-b ">
                            <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {RoomCategoryCombination["RoyalDeluxe"]}
                            </td>
                            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {Payment.RoyalDeluxe}
                            </td>

                        </tr>:""}
                        {Payment.PrestigeSuite!=0?<tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {RoomCategoryCombination["PrestigeSuite"]}
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {Payment.PrestigeSuite}
                                </td>

                            </tr>:""}
                        {Payment.ExclusiveRetreat!=0?<tr class="bg-white border-b ">
                            <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {RoomCategoryCombination["ExclusiveRetreat"]}
                            </td>
                            <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {Payment.ExclusiveRetreat}
                            </td>

                        </tr>:""}
                        </tbody>
                    </table>
                </div>



                {/* <div className='w-full font-semibold  text-[16px] md:text-[20px] text-green-600'>
                    <p>Meal Plan</p>
                </div> */}



                {/* <div class="relative overflow-x-auto w-full">
                    <table class="w-full text-sm text-left rtl:text-right">
                        <thead class="text-xs md:text-[12px] text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Plan Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Plan Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    0
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    1
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div> */}


                {/* <div className='w-full font-semibold  text-[16px] md:text-[20px] text-green-600'>
                    <p>Packages Selected</p>
                </div>

                <div class="relative overflow-x-auto w-full">
                    <table class="w-full text-sm text-left rtl:text-right">
                        <thead class="text-xs md:text-[12px] text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Package Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Package Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b ">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    0
                                </td>
                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    1
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div> */}


                <div className='w-full flex justify-center gap-10'>
                    <button onClick={handleDownloadClick} className='border-none py-2 px-4 bg-blue-500 text-white rounded-md'>
                        {isPDFGenerationInProgress ? 'Generating PDF...' : 'Download'}
                    </button>
                    <button className='border-none py-2 px-4 bg-blue-500  text-white rounded-md'>Close</button>
                </div>
            </div>
        </div>
    )
}

export default Success