import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthProvider';
const Booking = () => {

    const {FetchUsersBookings,FetchUsersFutureBookings,AllUserBookings,AllFutureUserBookings,DeleteUserBookings} = useContext(AuthContext);

    useEffect(()=>{
        FetchUsersBookings()
        FetchUsersFutureBookings()
    },[])

    const [Allbookings,setAllbookings] = useState(true)
    const [futureBookings,setfutureBookings] = useState(true)
    const [pastBookings,setpastBookings] = useState(true)

    return (
        <div className='maxwidth px-5'>
            <div className='overflow-auto w-full'>
                <input type='radio' name='bookings_radio' onClick={()=>{setAllbookings(true);setfutureBookings(true);setpastBookings(true)}} />All Bookings
                <input type='radio' name='bookings_radio' onClick={()=>{setfutureBookings(false);setpastBookings(true)}} />Past Bookings
                <input type='radio' name='bookings_radio' onClick={()=>{setfutureBookings(true);setpastBookings(false)}} />Future Bookings

                <table className=" overflow-scroll w-full">
                    <thead >
                        <tr className="bg-[#978667] border border-[#978667]">
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Booking Id</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Checked In</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Guest Name</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Email-Id</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Phone</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Total Price</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Remaining Amount</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Payment Status</th>
                            <th className='py-2 text-white text-[16px] whitespace-nowrap px-2 font-medium text-center'>Action</th>
                        </tr>
                    </thead>
                    {Allbookings?<>
                        {futureBookings?<tbody>
                            {AllFutureUserBookings.map((bookings)=>{
                                return <tr className='border'>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings.bookingId}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings.checkIn}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.guestInfo?.guestName}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.guestInfo.EmailId}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.guestInfo.Phone}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.price?.Total}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.price?.Total - bookings?.price?.amountPay}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.payment?.Status}</td>
                                    {bookings?.payment?.Status!=="CANCELLED"?<td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>
                                        <button onClick={() => { DeleteUserBookings(bookings.bookingId)}}>Cancel</button>
                                    </td>:<td>-</td>}
                                </tr>
                            })}
                        </tbody>:""}
                        {pastBookings?<tbody>
                            {AllUserBookings.map((bookings)=>{
                                return <tr className='border'>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings.bookingId}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings.checkIn}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.guestInfo?.guestName}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.guestInfo.EmailId}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.guestInfo.Phone}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.price?.Total}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.price?.Total - bookings?.price?.amountPay}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>{bookings?.payment?.Status}</td>
                                    <td className='py-2 text-dark text-[16px] whitespace-nowrap px-2 font-medium text-center'>Done</td>
                                </tr>
                            })}
                        </tbody>:""}
                    
                    </>:""}
                </table>
            </div>
        </div>
    )
}

export default Booking