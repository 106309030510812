import axios from "axios";
import React, { useContext, useState } from 'react';
import { BsFillSendFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import AuthContext from "../context/AuthProvider";
import SectionHead from "./SectionHead";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";

const Form = (props) => {
  const { domain } = useContext(AuthContext)
  const host = "https://nexon.eazotel.com/eazotel/addcontacts";

  const [formData, setFormData] = useState({
    username: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChnage = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const preparedData = {
        Domain: domain,
        email: formData.email,
        Name: formData.username,
        Contact: formData.phone,
        Description: formData.message,
      };
      const response = await axios.post(host, preparedData);

      // console.log(response.data);
    } catch (error) {
      console.log("Error: ", error);
    }
    // sssetFormData(formData);

    setFormData({
      username: "",
      phone: "",
      email: "",
      message: "",
    });
  };
  return (
    <div
      id="contact"
      className="!mt-20 maxwidth px-5 max-md:!mt-10 max-sm:!mt-10"
    >
      <SectionHead
        color={props.color}
        head={props.sectionData?.Title}
        // subHead={props.sectionData?.Description}
        subHead={""}
      />
      <div className="mt-5 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:gap-5 max-sm:flex-col">
          <div className="flex flex-col w-[64%] max-md:w-[50%] max-sm:w-[100%] max-md:ml-0 ">
            <div className="flex flex-col grow items-start max-md:mt-5 max-md:max-w-full">
              <div className="card-heading capitalize max-md:max-w-full" style={{ color: props.color ? props.color : "#978667" }}>
                Contact Details
              </div>
              <div className="flex gap-3 mt-6 items-center">
                <FiPhoneCall size={18} style={{ color: props.color ? props.color : "#978667" }} />
                <div className="paragraph">{props.phone}</div>
              </div>
              <div className="flex items-center gap-3 mt-4">
                <MdOutlineMailOutline size={18} style={{ color: props.color ? props.color : "#978667" }} />
                <div className="paragraph">{props.email}</div>
              </div>
              <div className="flex items-center gap-2.5 mt-4">
                <IoLocationOutline size={20} style={{ color: props.color ? props.color : "#978667" }} />

                <div className="paragraph">{props.address}</div>
              </div>
              <div className="w-full mt-10 max-md:mt-5">
                <iframe
                  src={props.location}
                  width="100%"
                  // height="317"
                  loading="lazy"
                  title="location"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="h-[317px] max-md:h-[298px] max-sm:h-[100%]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col  w-[36%]  max-md:ml-0 max-md:w-[50%] max-sm:w-[100%]">
            <div className="flex flex-col grow justify-center max-md:justify-start text-base max-md:mt-5">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col pt-7 pb-3 max-md:pt-5 w-full "
                style={{ backgroundColor: props.color ? "#F6F7EB" : "#978667" }}

              >
                <div className="card-heading capitalize px-6 max-md:px-2.5 max-md:mx-0 "
                  style={{ color: props.color ? props.color : "#978667" }}
                >
                  Get in Touch!
                </div>
                <div className="flex items-center gap-3 self-start mt-8 px-6 max-md:px-2.5 w-100">
                  <FaUser size={18} style={{ color: props.color ? props.color : "#978667" }} />
                  <input
                    type="text"
                    name="username"
                    placeholder="Your name"
                    value={formData.username}
                    onChange={handleChnage}
                    required
                    className="paragraph outline-none bg-transparent w-full"
                  />
                </div>
                <div className="shrink-0 mx-6 mt-6 max-w-full h-px border border-solid bg-[#978667] border-[#978667] max-md:mx-0"

                  style={{ border: props.color ? `1px solid ${props.color}` : "#978667" }}
                />
                <div className="flex items-center gap-3 self-start mt-8  px-6 max-md:px-2.5 whitespace-nowrap  w-full">
                  <FaPhone size={18} style={{ color: props.color ? props.color : "#978667" }} />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your Phone Number"
                    value={formData.phone}
                    onChange={handleChnage}
                    required
                    className="paragraph outline-none bg-transparent w-full"
                  />
                </div>
                <div className="shrink-0 mx-6 mt-6 max-w-full h-px border border-solid    max-md:mx-0"
                  style={{ border: props.color ? `1px solid ${props.color}` : "#978667" }}
                />
                <div className="flex items-center gap-3 self-start mt-8  px-6 max-md:px-2.5 whitespace-nowrap  w-full">
                  <MdEmail size={18} style={{ color: props.color ? props.color : "#978667" }} />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChnage}
                    required
                    className="paragraph outline-none bg-transparent w-full"
                  />
                </div>
                <div className="shrink-0 mx-6 mt-6 max-w-full h-px border border-solid bg-[#978667] border-[#978667]  max-md:mx-0"
                  style={{ border: props.color ? `1px solid ${props.color}` : "#978667" }}
                />
                <div className="flex items-center gap-3 self-start mt-8  px-6 max-md:px-2.5 whitespace-nowrap  w-full">
                  <BsFillSendFill size={18}
                    style={{ color: props.color ? props.color : "#978667" }}
                  />
                  <input
                    type="text"
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChnage}
                    required
                    className="paragraph outline-none bg-transparent w-full"
                  />
                </div>
                <div className="shrink-0 mx-6 mt-6 max-w-full h-px border border-solid bg-[#978667] border-[#978667]  max-md:mx-0"
                  style={{ border: props.color ? `1px solid ${props.color}` : "#978667" }} />
                <button
                  type="submit"
                  className=" justify-center items-center px-16 py-6 mt-11 font-semibold tracking-wide text-white whitespace-nowrap bg-[#978667] leading-[150%] max-md:px-5 max-md:mt-10"
                  style={{ backgroundColor: props.color ? props.color : "#978667" }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>

          {/* <div className="sm:hidden md:hidden max-md:order-1 max-sm:block w-full mt-10 max-md:mt-5">
            <iframe
              src={props.location}
              width="100%"
              loading="lazy"
              title="location"
              referrerPolicy="no-referrer-when-downgrade"
              className="h-[317px] max-md:h-[298px] max-sm:h-[100%]"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Form;
