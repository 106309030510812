import React, { useContext, useState } from "react";
import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import airconditioner from "../assets/svg/airconditioner.svg";
import breakfast from "../assets/svg/breakfast.svg";
import foodservice from "../assets/svg/service.svg";
import AuthContext from "../context/AuthProvider";

function CardsRoom({ Index, data, color }) {
  console.log(data);
  const { Available, RoomNameAvailable, RateChange, currency,
    setDelux, setDeluxAdult,
    setSuperDelux, setSuperDeluxAdult,
    setSuite, setSuiteAdult,
    setPremium, setPremiumAdult,
    setPremiereRetreat, setPremiereRetreatAdult,
    setEliteSuite, setEliteSuiteAdult,
    setGrandDeluxe, setGrandDeluxeAdult,
    setImperialSuite, setImperialSuiteAdult,
    setSupremeRetreat, setSupremeRetreatAdult,
    setRoyalDeluxe, setRoyalDeluxeAdult,
    setPrestigeSuite, setPrestigeSuiteAdult,
    setExclusiveRetreat, setExclusiveRetreatAdult, setRoomCategoryCombination } = useContext(AuthContext)
  const [imgSelect, setImgSelect] = useState("");
  const [previewImage, setPreviewImage] = useState(false);
  const [imgSelectIndex, setImgSelectIndex] = useState(0);
  let [roomCount, setRoomsCount] = useState(0);
  const [roomselect, setroomselect] = useState(false)


  if (data.roomType === "1") { setDeluxAdult(data.adult) }
  if (data.roomType === "2") { setSuperDeluxAdult(data.adult) }
  if (data.roomType === "3") { setSuiteAdult(data.adult) }
  if (data.roomType === "4") { setPremiumAdult(data.adult) }
  if (data.roomType === "5") { setPremiereRetreatAdult(data.adult) }
  if (data.roomType === "6") { setEliteSuiteAdult(data.adult) }
  if (data.roomType === "7") { setGrandDeluxeAdult(data.adult) }
  if (data.roomType === "8") { setImperialSuiteAdult(data.adult) }
  if (data.roomType === "9") { setSupremeRetreatAdult(data.adult) }
  if (data.roomType === "10") { setRoyalDeluxeAdult(data.adult) }
  if (data.roomType === "11") { setPrestigeSuiteAdult(data.adult) }
  if (data.roomType === "12") { setExclusiveRetreatAdult(data.adult) }

  const AddRoomCount = (roomType) => {
    if (roomCount >= Available[RoomNameAvailable[data.roomTypeName]]) {
      setRoomsCount(Available[RoomNameAvailable[data.roomTypeName]])
    }
    else {
      setRoomsCount(roomCount += 1)
      if (roomType === "1") {
        setDelux(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["DELUX"]: data.roomName,
        }));
      }
      if (roomType === "2") {
        setSuperDelux(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["SUPERDELUX"]: data.roomName,
        }));
      }
      if (roomType === "3") {
        setSuite(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["SUITE"]: data.roomName,
        }));
      }
      if (roomType === "4") {
        setPremium(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["PREMIUM"]: data.roomName,
        }));
      }
      if (roomType === "5") {
        setPremiereRetreat(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["PremiereRetreat"]: data.roomName,
        }));
      }
      if (roomType === "6") {
        setEliteSuite(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["EliteSuite"]: data.roomName,
        }));
      }
      if (roomType === "7") {
        setGrandDeluxe(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["GrandDeluxe"]: data.roomName,
        }));
      }
      if (roomType === "8") {
        setImperialSuite(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["ImperialSuite"]: data.roomName,
        }));
      }
      if (roomType === "9") {
        setSupremeRetreat(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["SupremeRetreat"]: data.roomName,
        }));
      }
      if (roomType === "10") {
        setRoyalDeluxe(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["RoyalDeluxe"]: data.roomName,
        }));
      }
      if (roomType === "11") {
        setPrestigeSuite(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["PrestigeSuite"]: data.roomName,
        }));
      }
      if (roomType === "12") {
        setExclusiveRetreat(roomCount)
        setRoomCategoryCombination((prevRoomcatname) => ({
          ...prevRoomcatname,
          ["ExclusiveRetreat"]: data.roomName,
        }));
      }
    }
  }

  const DelRoomCount = (roomType) => {
    if (roomCount <= 0) {
      setRoomsCount(0)
    }
    else {
      setRoomsCount(roomCount -= 1)
      if (roomType === "1") {
        setDelux(roomCount)
      }
      if (roomType === "2") {
        setSuperDelux(roomCount)
      }
      if (roomType === "3") {
        setSuite(roomCount)
      }
      if (roomType === "4") {
        setPremium(roomCount)
      }
      if (roomType === "5") {
        setPremiereRetreat(roomCount)
      }
      if (roomType === "6") {
        setEliteSuite(roomCount)
      }
      if (roomType === "7") {
        setGrandDeluxe(roomCount)
      }
      if (roomType === "8") {
        setImperialSuite(roomCount)
      }
      if (roomType === "9") {
        setSupremeRetreat(roomCount)
      }
      if (roomType === "10") {
        setRoyalDeluxe(roomCount)
      }
      if (roomType === "11") {
        setPrestigeSuite(roomCount)
      }
      if (roomType === "12") {
        setExclusiveRetreat(roomCount)
      }
    }
  }



  return (
    <div className="lg:grid lg:grid-cols-3 flex flex-col gap-4 w-full py-4 mt-5">
      <div className="lg:col-span-1 relative z-0">
        <Swiper
          loop={true}
          slidesPerView={1}
          autoplay={{
            delay: 4000,
          }}
          pagination={{
            el: `#bullets-rooms-${Index}`,
          }}
          navigation={{
            prevEl: ".prev-btn",
            nextEl: ".next-btn",
          }}
          modules={[Pagination, Autoplay, Navigation]}
          breakpoints={{
            768: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
          }}
        >
          {data.roomImage.map((details, index) => {
            return (
              <SwiperSlide>
                <div
                  className="lg:h-[284px] h-auto cursor-pointer relative z-[0]"
                  onClick={() => {
                    setImgSelect([...data.roomImage]);
                    setPreviewImage(true);
                  }}
                >
                  <img src={details} alt="img" className="w-full h-full" />
                </div>
              </SwiperSlide>
            );
          })}
          {previewImage && (
            <ImagePreview
              imgSelectIndex={imgSelectIndex}
              imgSelect={imgSelect}
              setImgSelectIndex={setImgSelectIndex}
              setPreviewImage={setPreviewImage}
            />
          )}
        </Swiper>
        <div id={`bullets-rooms-${Index}`} className="w-fit mx-auto"></div>
      </div>

      <div className="lg:col-span-2">
        <div className="flex flex-row items-center justify-between sm:items-center font-bold">
          <div className="flex sm:flex-row flex-col sm:items-center sm:gap-6 gap-1">
            <h2 className="sm:text-4xl text-xl">{data.roomName}</h2>
            {/* <p className="font-bold sm:text-sm text-xs">
              500 sq/ft, 3 guests per room
            </p> */}
          </div>
          <span className="text-[#FF0606] italic text-xs">
            *Last {Available[RoomNameAvailable[data.roomTypeName]]} rooms available
          </span>
        </div>

        <p className="mt-4 sm:text-lg text-sm text-justify">
          {data.roomDescription}
        </p>

        <div className="grid md:grid-cols-5 gap-5 mt-4">
          <div className="md:col-span-4  p-4 min-h-[156px]" style={{ backgroundColor: color }}>
            <h2 className="text-[#fff] text-lg font-bold">Amenities</h2>
            <div className="grid grid-cols-3 mt-4">
              {data.roomFacilities.airConditonar && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={airconditioner} width={18} />
                  <p className="text-sm">24x7 Air conditioning</p>
                </div>
              )}

              {data.roomFacilities.freeBreakfast && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={breakfast} width={18} />
                  <p className="text-sm">Complimentary Breakfast</p>
                </div>
              )}

              {data.roomFacilities.wifi && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">wifi</p>
                </div>
              )}

              {data.roomFacilities.television && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">television</p>
                </div>
              )}

              {data.roomFacilities.hairdryers && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">hairdryers</p>
                </div>
              )}

              {data.roomFacilities.coffeeMakers && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">coffeeMakers</p>
                </div>
              )}

              {data.roomFacilities.directDial && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">directDial</p>
                </div>
              )}

              {data.roomFacilities.tableWithChair && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">tableWithChair</p>
                </div>
              )}

              {data.roomFacilities.alarmclock && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">alarmclock</p>
                </div>
              )}

              {data.roomFacilities.electronicLocker && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">electronicLocker</p>
                </div>
              )}

              {data.roomFacilities.fridge && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">fridge</p>
                </div>
              )}

              {data.roomFacilities.bathroomWithShower && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">bathroomWithShower</p>
                </div>
              )}

              {data.roomFacilities.freeBreakfast && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">freeBreakfast </p>
                </div>
              )}

              {data.roomFacilities.kidEquipment && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">freeBreakfast </p>
                </div>
              )}

              {data.roomFacilities.Balcony && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm"> Balcony</p>
                </div>
              )}

              {data.roomFacilities.Bath && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Bath</p>
                </div>
              )}

              {data.roomFacilities.Electrickettle && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Electrickettle</p>
                </div>
              )}

              {data.roomFacilities.Dressingroom && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Dressingroom</p>
                </div>
              )}

              {data.roomFacilities.Fan && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Fan</p>
                </div>
              )}

              {data.roomFacilities.Fireplace && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Fireplace</p>
                </div>
              )}

              {data.roomFacilities.Heating && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Heating</p>
                </div>
              )}

              {data.roomFacilities.Iron && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Iron</p>
                </div>
              )}

              {data.roomFacilities.Pajamas && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">ajamas</p>
                </div>
              )}

              {data.roomFacilities.Toilet_paper && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Toilet_paper</p>
                </div>
              )}

              {data.roomFacilities.Bidet && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Bidet</p>
                </div>
              )}

              {data.roomFacilities["Bath_shower"] && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Bath_shower</p>
                </div>
              )}

              {data.roomFacilities.Bathrobe && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Bathrobe </p>
                </div>
              )}

              {data.roomFacilities["Free_toiletries"] && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Free_toiletries</p>
                </div>
              )}

              {data.roomFacilities.Minibar && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Minibar</p>
                </div>
              )}

              {data.roomFacilities.Kitchen && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">Kitchen</p>
                </div>
              )}

              {data.roomFacilities["City_view"] && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">24x7 Room service</p>
                </div>
              )}

              {data.roomFacilities["Garden_view"] && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">24x7 Room service</p>
                </div>
              )}

              {data.roomFacilities["Lake_view"] && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">24x7 Room service</p>
                </div>
              )}

              {data.roomFacilities["River_view"] && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">24x7 Room service</p>
                </div>
              )}

              {data.roomFacilities["Sea_view"] && (
                <div className="flex items-center gap-4">
                  <img alt="facilitiesIcon" src={foodservice} width={18} />
                  <p className="text-sm">24x7 Room service</p>
                </div>
              )}
            </div>
          </div>
          <div className="md:col-span-1">
            <div className="flex flex-col md:items-end" style={{ color: color }}>
              <p className="text-2xl font-bold">{roomCount * RateChange[data.roomType]?.Price === 0 ? RateChange[data.roomType]?.Price : roomCount * RateChange[data.roomType]?.Price} {currency}</p>
              <span className="text-xs">per night</span>
            </div>
            {roomCount != 0 && Available[RoomNameAvailable[data.roomTypeName]] != 0 ? <div className="flex justify-between items-center gap-5 mt-4">
              <p className="text-[#000000] font-bold">Room(s)</p>
              <div className=" text-white px-2 py-2 flex justify-between gap-3 items-center font-normal" style={{ backgroundColor: color }}>
                <div
                  className="w-4 h-4 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    DelRoomCount(data.roomType)
                  }}
                >
                  -
                </div>
                {roomCount}
                <div
                  className="w-4 h-4 flex items-center justify-center cursor-pointer"
                  onClick={() => AddRoomCount(data.roomType)}
                >
                  +
                </div>
              </div>
            </div> : Available[RoomNameAvailable[data.roomTypeName]] == 0 ?
              <div>
                <button className="bg-[#FF0000] text-white uppercase py-2 mt-4 w-full  text-lg font-bold">
                  Sold Out
                </button>
              </div> : <div>
                <button onClick={() => { AddRoomCount(data.roomType) }} className=" text-white uppercase py-2 mt-4 w-full text-lg font-bold" style={{ backgroundColor: color }}>
                  Add Room
                </button>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

const ImagePreview = ({
  imgSelectIndex,
  imgSelect,
  setImgSelectIndex,
  setPreviewImage,
}) => {
  const slideRight = () => {
    if (imgSelectIndex === imgSelect.length - 1) return setImgSelectIndex(0);
    setImgSelectIndex(imgSelectIndex + 1);
  };

  const slideLeft = () => {
    if (imgSelectIndex <= 0) return setImgSelectIndex(imgSelect.length - 1);
    setImgSelectIndex(imgSelectIndex - 1);
  };
  return (
    <div className="fixed top-0 left-0 w-[100%] h-[100vh] bg-[#000000c6] z-[9999] flex flex-col justify-center items-center">
      <div className="relative w-[100%] h-[50%] md:w-[90%] md:h-[90%] m-auto">
        <img alt="facilitiesIcon"
          src={imgSelect[imgSelectIndex]}
          loading="lazy"
          decoding="async"
          aria-hidden="true"
          // alt={""}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
        <p
          className="text-2xl text-white bg-slate-900 w-10 h-10 flex justify-center items-center rounded-full mb-4 cursor-pointer absolute top-[-2.8%] right-[-1%]"
          onClick={() => {
            setPreviewImage(false);
            window.document.body.classList.remove("bg_salmon");
          }}
        >
          X
        </p>
      </div>
      <span
        className="fixed top-[50%] md:left-[2rem] left-[4rem] p-4 bg-[#DEB666] cursor-pointer rounded-lg"
        onClick={() => {
          slideLeft();
        }}
      >
        <IoMdArrowDropleftCircle size={20} />
      </span>
      <span
        className="fixed top-[50%] md:right-[2rem] right-[4rem] p-4 bg-[#DEB666] cursor-pointer rounded-lg"
        onClick={() => {
          slideRight();
        }}
      >
        <IoMdArrowDroprightCircle size={20} />
      </span>
    </div>
  );
};

export default CardsRoom;

//data.roomFacilities

// wifi
// television
// airConditonar
// hairdryers
// coffeeMakers
// directDial
// tableWithChair
// alarmclock
// electronicLocker
// fridge
// bathroomWithShower
// freeBreakfast
// kidEquipment
// Balcony
// Bath
// Electrickettle
// Dressingroom
// Fan
// Fireplace
// Heating
// Iron
// Pajamas
// Toilet_paper
// Bidet
// Bath_shower
// Bathrobe
// Free_toiletries
// Minibar
// Kitchen
// City_view
// Garden_view
// Lake_view
// River_view
// Sea_view
