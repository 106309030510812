import React, { useState } from "react";
import SectionHead from "./SectionHead";

const About = (props) => {
  // console.log(props);
  const [showFullText, setShowFullText] = useState(false);

  const trimmedText = props.text ? props.text.slice(0, 1100) : "";

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div id="about" className="!mt-20 maxwidth px-5">
      {/* <SectionHead
        head={props?.sectionData?.Title}
        subHead={props?.sectionData?.Description}
      /> */}
      <div className=" grid grid-cols-2 gap-5 max-sm:grid-cols-1 mt-10">
        <div className="">
          <img
            loading="lazy"
            src={props.imageUrl}
            alt="about"
            className="grow object-cover w-full aspect-[1.37] max-md:mt-2  max-md:max-w-full"
          />
        </div>
        <div className="">
          <div className="flex flex-col self-stretch my-auto max-md:max-w-full">
            <div className="section-heading max-md:text-[24px] !text-left"
              style={{ color: props.color ? props.color : "#978667" }}
            >
              {props.heading}
            </div>
            <div className="max-md:hidden mt-6 max-md:mt-0 paragraph !text-justify">
              {showFullText ? props.text : trimmedText}
              <span>
                {" "}
                {props.text && props.text.length > 1100 && (
                  <span
                    onClick={toggleShowFullText}
                    className="text-blue-500 hover:underline mt-2"
                  >
                    {showFullText ? "Read less" : "Read more"}
                  </span>
                )}
              </span>
            </div>

            <div className="md:hidden mt-6 max-md:mt-0 paragraph !text-justify">
              {trimmedText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
