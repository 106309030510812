import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthProvider'
import "../style/Navmenu.css"




const Navmenu = (props) => {
    const { isMenuOpen, setIsMenuOpen, setisAuthenticatedUser } = useContext(AuthContext)
    const meHuParameter = "meHuParameter"
    const handleCloseMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const handleLogoutPopup = () => {
        localStorage.clear()
        setisAuthenticatedUser(false)
    }

    return (
        <div className='navmenu' onBlur={handleCloseMenu}>

            <div className='flex flex-col gap-1 border rounded-[10px]' onClick={() => { handleCloseMenu() }} style={{ height: "auto" }}>
                <Link className=' px-5 py-1 hover:bg-[#978667] hover:text-[#fff]' to={`/profile`}>Profile</Link>
                <Link className='px-5 py-1 hover:bg-[#978667] hover:text-[#fff]' to={`/booking`}>Bookings</Link>
                <Link className=' px-5 py-1 hover:bg-[#978667] hover:text-[#fff]' onClick={handleLogoutPopup} to={`/`}>Logout</Link>

            </div>

        </div>
    )
}

export default Navmenu