import React, { useContext, useState } from 'react';
import { IoClose } from "react-icons/io5";
import AuthContext from '../context/AuthProvider';
const Register = () => {


    const [active, setActive] = useState(false);
    const { setIsRegisterPopup,linktohit,setisAuthenticatedUser,FetchUsersInfo,FetchUsersBookings,
        FetchUsersFutureBookings} = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        number:""
    });

    const [Message,setMessage] = useState("");
    const [sentotp,setsentotp] = useState(false);
    const [otp,setotp] = useState("");


    const handleChange = (e) => {

        const { name, value } = e.target;
        setFormData({
            ...formData, [name]: value
        })

    }

    const handleSubmit = async() => {
        try{
            const response = await fetch(`${linktohit}/feature1/signup`, {
                method: "POST",
                headers: {
                  Accept: "application/json, text/plain, /",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "register":true,
                    "Name":formData["name"],
                    "emailId":formData["email"],
                    "phone":formData["number"],
                    "hotelid":localStorage.getItem("hid"),
                    "ndid":localStorage.getItem("hotelid")
                }),
              });
        
            const json = await response.json();
            if(json.Status){
                console.log(json)
                setsentotp(true)
            }
            else{
                // console.log(json)
                setMessage(json.Message)
            }
        }
        catch{}


    }

    const submitOtp = async () => {
        
        try{
            const response = await fetch(`${linktohit}/feature1/otp-verify`, {
                method: "POST",
                headers: {
                  Accept: "application/json, text/plain, /",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "hotelid":localStorage.getItem("hid"),
                    "ndid":localStorage.getItem("hotelid"),
                    "phone":formData["number"],
                    "otp":otp
                }),
              });
        
            const json = await response.json();
            if(json.Status){
                localStorage.setItem("engineAuth",json.Token)
                setisAuthenticatedUser(true);
                FetchUsersInfo()
                FetchUsersBookings()
                FetchUsersFutureBookings()
                setIsRegisterPopup(false);
            }
            else{
                setisAuthenticatedUser(false)
                console.log(json)
            }
        }
        catch{}
    }

    const handleRegisterPopup = () => {
        setIsRegisterPopup(false);
    }

    return (

        <div className=' fixed flex justify-center items-center top-0 w-full h-screen bg-gray-900 bg-opacity-50 z-50'>

            <div className=' relative flex flex-col gap-4  max-w-[600px] md:w-[400px] mx-auto z-50 bg-[#FAF8F5] p-5'>
                <div className='absolute top-[-10px] right-[-10px] bg-[#978667] rounded-[50%] cursor-pointer' onClick={handleRegisterPopup}>
                    <IoClose color="white" size={30} className='p-1' />
                </div>
                <h1 className='text-center text-[30px] font-semibold text-[#978667]'>Sign Up</h1>
                <div className='flex flex-col'>
                    <label htmlFor='name'>Name</label>
                    <input type='text'
                        placeholder='Name'
                        name='name'
                        value={formData?.name}
                        onChange={handleChange}
                        className='py-2 px-4 outline-none border-2' />
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='email'>Email</label>
                    <input type='email'
                        placeholder='abc@example.com'
                        name='email'
                        value={formData?.email}
                        onChange={handleChange}
                        className='py-2 px-4 outline-none border-2' />
                </div>
                

                <div className='flex flex-col'>
                    <label htmlFor='email'>Phone Number</label>
                    <input type='email'
                        placeholder='Number'
                        name='number'

                        value={formData?.number}
                        onChange={handleChange}
                        className='py-2 px-4 outline-none border-2' />
                    {Message===""?"":<p style={{color:"red"}}>{Message}</p>}
                </div>

                {sentotp?<div className='flex flex-col'>
                    <label htmlFor='email'>OTP</label>
                    <input type='number'
                        placeholder='otp here'
                        name='otp'
                        value={otp}
                        onChange={(e)=>{setotp(e.target.value)}}
                        className='py-2 px-4 outline-none border-2' />
                </div>:""}
                {sentotp?<button onClick={submitOtp} className='py-2 px-5 bg-[#978667] text-[white]' >Submit Otp</button>
                :
                <button onClick={handleSubmit} className='py-2 px-5 bg-[#978667] text-[white]' >Register</button>}

            </div>



        </div>
    )
}

export default Register